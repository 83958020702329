@tailwind base;
@tailwind components;
@tailwind utilities;

/* Webkit browsers (Chrome, Edge, Safari) */
::-webkit-scrollbar {
  width: 4px; /* Thin scrollbar */
  height: 6px; /* For horizontal scrollbar */
}

.chat-message a {
  text-decoration: underline;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(44, 43, 43); /* Gray scrollbar thumb */
  border-radius: 3px; /* Rounded edges */
}

::-webkit-scrollbar-track {
  background: transparent; /* Optional: Make track transparent */
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: gray transparent;
}
